<template>
    <div class="label-scroll">
        <button class="left navbar-link" @click="scrollLeft" :class="{disabled: isScrolledLeft}">
            <FontAwesomeIcon icon="caret-left"/>
        </button>
        <div class="scroll" :class="{left: !isScrolledLeft, right: !isScrolledRight}" @scroll="updateScrollFlags">
            <button
                v-for="label in labels"
                :key="label.id"
                :class="{active: label == $store.state.activeLabel}"
                @click="$store.commit('selectLabel', label)"
                class="navbar-link">
                {{ label.name }}
            </button>
        </div>
        <button class="right navbar-link" @click="scrollRight" :class="{disabled: isScrolledRight}">
            <FontAwesomeIcon icon="caret-right"/>
        </button>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'LabelScroll',
    data() {
        return {
            labels: [],
            scrollElement: null,
            scrolling: null,
            isScrolledLeft: true,
            isScrolledRight: true
        }
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        scrollLeft() {
            this.scrollElement.scrollTo({
                left: this.scrollElement.scrollLeft - 150,
                behavior: 'smooth'
            })
        },
        scrollRight() {
            this.scrollElement.scrollTo({
                left: this.scrollElement.scrollLeft + 150,
                behavior: 'smooth'
            })
        },
        updateScrollFlags() {
            this.isScrolledLeft = this.scrollElement.scrollLeft == 0
            this.isScrolledRight = this.scrollElement.scrollLeft + 1 > this.scrollElement.scrollWidth - this.scrollElement.clientWidth
        }
    },
    created() {
        this.axios.get('labels/')
        .then(resp => {
            this.labels = resp.data.labels
            if (this.labels.length) {
                this.$store.commit('selectLabel', this.labels[0])
                this.$nextTick(() => {
                    this.updateScrollFlags()
                })
            }
        })
        .catch(() => {})
    },
    mounted() {
        this.scrollElement = this.$el.querySelector('.scroll')
    }
}
</script>

<style scoped>
.label-scroll {
    overflow-x: hidden;
    white-space: nowrap;
    display: flex;
}
.scroll {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0 .5rem;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.scroll.left {
    border-left: 1px solid green;
}
.scroll.right {
    border-right: 1px solid green;
}
.navbar-link.left {
    margin: 0;
}
.navbar-link.left.disabled {
    opacity: .2;
}
.navbar-link.right {
    margin: 0;
}
.navbar-link.right.disabled {
    opacity: .2;
}
</style>